@font-face {
	font-family: 'Source Sans Pro';
	src: url('../../dist/fonts/sourcesans/sourcesanspro-bold-webfont.eot');
	src: url('../../dist/fonts/sourcesans/sourcesanspro-bold-webfont.eot?#iefix') format('embedded-opentype'),
		url('../../dist/fonts/sourcesans/sourcesanspro-bold-webfont.woff2') format('woff2'),
		url('../../dist/fonts/sourcesans/sourcesanspro-bold-webfont.woff') format('woff'),
		url('../../dist/fonts/sourcesans/sourcesanspro-bold-webfont.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../../dist/fonts/sourcesans/sourcesanspro-bolditalic-webfont.eot');
	src: url('../../dist/fonts/sourcesans/sourcesanspro-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
		url('../../dist/fonts/sourcesans/sourcesanspro-bolditalic-webfont.woff2') format('woff2'),
		url('../../dist/fonts/sourcesans/sourcesanspro-bolditalic-webfont.woff') format('woff'),
		url('../../dist/fonts/sourcesans/sourcesanspro-bolditalic-webfont.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}


@font-face {
	font-family: 'Source Sans Pro';
	src: url('../../dist/fonts/sourcesans/sourcesanspro-italic-webfont.eot');
	src: url('../../dist/fonts/sourcesans/sourcesanspro-italic-webfont.eot?#iefix') format('embedded-opentype'),
		url('../../dist/fonts/sourcesans/sourcesanspro-italic-webfont.woff2') format('woff2'),
		url('../../dist/fonts/sourcesans/sourcesanspro-italic-webfont.woff') format('woff'),
		url('../../dist/fonts/sourcesans/sourcesanspro-italic-webfont.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;

}

@font-face {
	font-family: 'Source Sans Pro';
	src: url('../../dist/fonts/sourcesans/sourcesanspro-regular-webfont.eot');
	src: url('../../dist/fonts/sourcesans/sourcesanspro-regular-webfont.eot?#iefix') format('embedded-opentype'),
		url('../../dist/fonts/sourcesans/sourcesanspro-regular-webfont.woff2') format('woff2'),
		url('../../dist/fonts/sourcesans/sourcesanspro-regular-webfont.woff') format('woff'),
		url('../../dist/fonts/sourcesans/sourcesanspro-regular-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
