.top-menu {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	margin-top: 0;
	z-index: +2;

	@include breakpoint (medium down) {

		.columns {
			padding: 0;
		}
	}

	.menu {
		@include breakpoint (small down) {
			height: 100vh;
			position: relative;
		}

		.active {
			a {
				background: none;
				color: $orange;
			}
		}
	}

	.top-bar {
		width: 100%;
		position: relative;
		margin-top: 55px;
		padding: 0;
		background-color: white;


		@include breakpoint (medium down) {
			z-index: +2;
			margin-top: 0;
			border-bottom: 1px solid $gray;
		}

		span {
			img {
				height: 30px;
				width: auto;
			}
		}
	}
	.menu-icon {
		margin-right: 20px;
		z-index: +1;
		background-color: black;
		height: 21px;

		@include breakpoint (medium down) {
			position: absolute;
			right: 0;
			top: 15px;
		}

		&::after {
			height: 3px;
		}
	}

	ul {
		margin-left: 35px;
		margin-top: -5px;
		padding: 0;
		justify-content: flex-end;
		background-color: white;

		@include breakpoint (medium down) {
			margin: 0;
			padding: 0;
			display: block;
		}

		li {
			display: inline-block;
			font-family: $header-font-family;
			font-weight: 400;
			font-size: rem-calc(17);
			letter-spacing: .059em;
			text-transform: uppercase;
			padding: 0 8px 0 1px;


			@include breakpoint (large down) {
				padding: 0 5px 0 0;
				font-size: rem-calc(16);
			}

			@include breakpoint (medium down) {
				padding: 0;
				font-size: rem-calc(14);
				font-weight: 400;
			}

			@include breakpoint (small down) {
				display: block;
				padding: 10px 0 10px 20px;
				border-bottom: 1px solid $gray;

				&a {
					display: block;
				}
			}

			&:last-child {
				padding-right: 0;
			}

			a {
				color: $blue;
				transition: .2s ease-in;
				display: block;

				&:hover {
					color: $orange;
				}

				&:focus {
					outline: none;
				}
			}
		}
	}
}

.f-nav {
	background-color: white;
	box-shadow: 0 0 5px 0 $dark-gray;
	transition: .5s;
	margin-top: -40px;
	z-index: +3;

	@include breakpoint(xsmall down) {
		margin-top: 0;
	}
}
