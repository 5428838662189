form {
	margin-top: 40px;

	.error-tooltip,
	.message {
		font-size: rem-calc(14);
		color: $orange;
	}

	.error-tooltip {
		padding: 8px 0 20px 0;
		width: 100%;
	}

	.message {
		padding: 20px 0 10px 0;
	}
}

label {
	line-height: 1.4em;
}

input[type='text'],
input[type='email'] {
	height: 60px;
}

input[type='text'],
input[type='email'],
textarea {
	position: relative;
	display: block;
	margin: 0;
	padding: 18px 20px 0 20px;
	border: 1px solid $border-color-x;
	background: transparent;

	&:focus {
		border: 1px solid $blue;
		z-index: +1;
		background: transparent;
	}
}

textarea {
	resize: vertical;
	padding-top: 28px;
	padding-bottom: 14px;
	min-height: 160px;
}

.g-recaptcha {
	display: inline-block;
}

button,
.button {
	margin-top: 0;
	outline: none;

	.blue {
		@include breakpoint (medium down) {
			text-align: center;
			background: $black;
		}
	}
}

.form-field {
	position: relative;
	margin-top: 20px;
	margin-bottom: 20px;
	overflow: hidden;

	&.overlap {
		margin-top: -1px;
		margin-bottom: 0;
	}

	&.checkbox {
		display: flex;
		flex-wrap: wrap;

		input[type='checkbox'] {
			margin: 5px 20px 20px 10px;
		}

		p {
			width: 80%;
			user-select: none;
		}
	}

	&__desc {
		transition: .3s;
		position: absolute;
		z-index: -1;
		left: 0;
		top: 20px;
		padding-left: 22px;
		font-size: rem-calc(15);
		color: $dark-gray;
		will-change: transform;

		input:focus + &,
		textarea:focus + &,
		.is-dirty & {
			color: $gray;
			font-size: rem-calc(14);
			z-index: +1;
			transform: translateY(-12px);
		}
	}
}

.contact-form {
	.button {
		float: right;

		@include breakpoint (large only) {
			float: left;
			margin-bottom: 50px;
		}
	}

	.rodo-info {
		margin-bottom: 30px;
		font-size: 12px;
		line-height: 1.4em;
	}
}