.empty-info {
	margin-top: 100px;

	@include breakpoint (medium down) {
		margin-top: 0;
		text-align: center;
		display: block;
	}

	h1 {
		padding: 150px 0 0;

		@include breakpoint (medium down) {
			padding-top: 20px 0 0 0;
		}
	}
	.button {
		svg {
			transform: rotate(180deg);
			margin: 0 10px 0 0;
			padding: 0;
		}
	}
}
