@font-face {
	font-family: 'Malleable';
	src: url('../../dist/fonts/malleable/malleable-fp-thin.eot');
	src: local('Malleable-FP Thin'),
		local('Malleable Grotesque Thin'),
		url('../../dist/fonts/malleable/malleable-fp-thin.eot?#iefix') format('embedded-opentype'),
		url('../../dist/fonts/malleable/malleable-fp-thin.woff') format('woff'),
		url('../../dist/fonts/malleable/malleable-fp-thin.woff2') format('woff2'),
		url('../../dist/fonts/malleable/malleable-fp-thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Malleable';
	src: url('../../dist/fonts/malleable/malleable-fp-light.eot');
	src: local('Malleable-FP Light'),
		local('Malleable Grotesque Light'),
		url('../../dist/fonts/malleable/malleable-fp-light.eot?#iefix') format('embedded-opentype'),
		url('../../dist/fonts/malleable/malleable-fp-light.woff') format('woff'),
		url('../../dist/fonts/malleable/malleable-fp-light.woff2') format('woff2'),
		url('../../dist/fonts/malleable/malleable-fp-light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Malleable';
	src: url('../../dist/fonts/malleable/malleable-fp-regular.eot');
	src: local('Malleable-FP'),
		local('Malleable Grotesque'),
		local('Malleable Grotesque Regular'),
		url('../../dist/fonts/malleable/malleable-fp-regular.eot?#iefix') format('embedded-opentype'),
		url('../../dist/fonts/malleable/malleable-fp-regular.woff') format('woff'),
		url('../../dist/fonts/malleable/malleable-fp-regular.woff2') format('woff2'),
		url('../../dist/fonts/malleable/malleable-fp-regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
