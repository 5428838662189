.footer {
	background: $medium-gray;
	padding: 50px 0 120px;

	@include breakpoint (large only) {
		padding-left: 20px;
	}

	@include breakpoint (small down) {
		padding: 50px 0;
	}

	svg {
		width: 145px;
		height: 50px;
	}

	a {
		color: $blue;
		transition: .3s ease-in-out;
		display: block;
		font-size: rem-calc(13);

		&:hover {
			color: $orange;
			padding-left: 2px;
		}
	}

	.fp {
		font-size: rem-calc(14);
		margin-top: 25px;
	}

	.legal {
		font-size: rem-calc(12);
		color: $dark-gray;
	}

	.tel {
		font-size: rem-calc(26);

		span {
			color: $dark-gray;
		}
	}

	.cr {
		@include breakpoint (medium only) {
			margin-left: 30px;
			padding-right: 20px;
		}
	}

	.contact-data {
		a {
			font-size: rem-calc(14);

			&:hover {
				padding: 0;
			}
		}
	}

	h3 {
		text-transform: uppercase;
		font-size: rem-calc(16);
		letter-spacing: .1em;
		padding-bottom: 30px;

		@include breakpoint (small down) {
			font-size: rem-calc(16);
			margin: 20px 0 0;
			padding-bottom: 10px;
		}
	}

	h4 {
		padding-bottom: 15px;
	}

	ul {
		margin: 0;
		list-style: none;

		li {
			a {
				font-size: rem-calc(13);
			}

			@include breakpoint (small down) {
				font-size: rem-calc(14);
			}
		}
	}
}
