.header {
	background-image: url(#{$theme-url-images}/backgrounds/bg-top.png);
	background-position: center;
	background-repeat: no-repeat;

	.language {
		position: relative;
		text-align: right;
		padding-top: 8px;

		@include breakpoint (medium down) {
			padding: 0;
			position: absolute;
			z-index: +10;
			top: 12px;
			right: 10%;
		}

		@include breakpoint (medium only) {
			top: 65px;
			right: 0;
		}

		ul {
			position: absolute;
			right: 0;
			margin-top: 0;
			z-index: +1;

			li {
				font-family: $body-font-family;
				padding-right: 5px;

				@include breakpoint(small down) {
					display: inline-block;
					text-align: right;
					padding: 0;
					border: none;
				}

				a {
					font-size: rem-calc(12);
					font-weight: 900;
					color: $dark-gray;
					padding: 3px 30px;

					@include breakpoint(small down) {
						font-size: rem-calc(16);
					}
				}
			}

			.is-accordion-submenu-parent {
				a {
					&::after {
						border-color: $gray transparent transparent;
					}
				}
			}

			ul {
				li {
					&:lang(pl) {
						&:first-child {
							display: none;
						}
					}
					&:lang(en) {
						&:last-child {
							display: none;
						}
					}
				}
			}
		}

		&::after {
			content: url(#{$theme-url-images}/icons/png/w_map.png);
			position: absolute;
			right: 50px;
			top: 10px;

			@include breakpoint (medium down) {
				content: '';
			}
		}
	}

	.logo {
		width: 230px;
		height: 50px;
		margin-top: 31px;

		@include breakpoint (large only) {
			padding: 0 20px;
			margin-top: 60px;
		}

		@include breakpoint (medium down) {
			margin-top: 10px;
			margin-left: 10px;
		}

		@include breakpoint (medium only) {
			margin-left: 15px;
		}

		@include breakpoint (small down) {
			display: none;
		}
	}

	h1 {
		margin-top: 10px;

		@include breakpoint (large only) {
			padding-left: 20px;
		}
	}
}

.mobile-logo {
	display: none;
	width: 210px;
	height: 65px;
	padding: 0 5px 0 0;
	background-image: url(#{$theme-url-images}/logos/smart-flow-logo.png);
	background-size: 100%;
	background-repeat: no-repeat;

	@include breakpoint (small down) {
		display: inline-block;
		height: 55px;
		padding: 5px 5px 0 0;
	}

}

.logo-link {
	color: white;
	display: inline-block;
	width: 190px;
	height: 74px;
	background-image: url(#{$theme-url-images}/logos/smart-flow-logo.png);
	background-size: 100%;
	background-repeat: no-repeat;
}
