body {
	background: white;
	color: $body-color;
	overflow-x: hidden;
	font-weight: 300;
}

img {
	max-width: 100vw;
}

.button {
	position: relative;
	text-transform: uppercase;
	font-family: $header-font-family;
	letter-spacing: .08em;
	font-weight: 400;

	@include breakpoint (small down) {
		font-size: rem-calc(12);
	}

	svg {
		color: black;
		margin-left: 25px;
		opacity: .7;
	}

	&.white {
		margin-top: 40px;
		background-color: white;



		&:hover {
			background: $orange;
			color: white;

			svg {
				margin-left: 30px;
			}
		}
	}

	&.blue {
		margin-top: 15px;
		background-color: $blue;
		color: white;

		&:hover {
			background: $orange;
			color: black;
			position: relative;

			svg {
				margin-left: 30px;
			}
		}
	}
}

h1 {
	font-size: rem-calc(45);
	font-weight: 100;
	padding: 225px 0 95px;
	letter-spacing: .033em;

	@include breakpoint(medium down) {
		text-align: center;
	}

	@include breakpoint(small down) {
		padding: 60px 0 30px;
	}
}

h2 {
	font-size: rem-calc(46);
	text-transform: uppercase;
	font-weight: 100;
	letter-spacing: .03em;
	position: relative;

	small {
		font-size: rem-calc(16);
		color: $lighten-gray;
		margin-left: -25px;
		margin-top: 20px;
		margin-right: 10px;
		vertical-align: text-top;
		display: inline-block;
		letter-spacing: .03em;
		font-weight: 400;
	}
}

h3 {
	font-size: rem-calc(20);
	letter-spacing: .01em;
	font-weight: 400;
	color: $orange;
}

h4 {
	font-size: rem-calc(17);
	letter-spacing: .06em;
	text-transform: uppercase;
}

.icon-16 {
	width: 16px;
	height: 16px;
	display: inline-block;
	stroke-width: 2;
	stroke-linecap: round;
	stroke-linejoin: round;
	vertical-align: middle;
	transition: all .2s;
}

.icon-48 {
	width: 72px;
	height: 72px;
	display: inline-block;
	stroke-width: 1;
	stroke-linecap: round;
	stroke-linejoin: round;
	vertical-align: middle;
	transition: all .2s;
}

.start {
	h1 {
		@include breakpoint (small down) {
			margin-top: 40px;
			font-size: 38px;
		}
	}
}
.intro {
	position: relative;
	background: $blue;
	background-image: url(#{$theme-url-images}/backgrounds/background-2.png);
	background-position: center bottom -46px;
	background-repeat: no-repeat;
	padding-bottom: 0;

	@include breakpoint (medium down) {
		background-image: none;
		padding-left: 20px;
	}

	@include breakpoint (large only) {
		padding: 0 20px;
	}

	.intro-txt {
		font-size: rem-calc(18);
		color: white;
		padding-top: 45px;
		padding-bottom: 7px;
		letter-spacing: .01em;
		line-height: 28px;
		text-shadow: 1px 1px 5px $dark-gray;

		@include breakpoint (small down) {
			padding-top: 10px;
		}

		.button {
			text-shadow: none;

			> svg {
				transform: rotate(90deg);
			}
		}
	}
}

.solution {
	background: $blue;
	margin-bottom: -145px;
	padding-top: 25px;
	font-size: rem-calc(15);

	h2 {
		padding-top: 20px;
		padding-bottom: 0;

		@include breakpoint (large only) {
			margin-left: 20px;
		}
	}
}

.box-overflow {
	position: relative;
	background: white;
	margin: 20px -90px 115px -90px;
	padding: 60px 90px 80px;
	box-shadow: 5px 25px 150px 5px rgba(black, .2);

	@include breakpoint (large down) {
		margin: 115px 0;
		padding: 0 20px;
	}

	@include breakpoint (large only) {
		margin: 30px 0;
	}

	@include breakpoint (small down) {
		width: 100%;
		margin: 0;
		padding: 0;
	}

	p {
		position: relative;
		font-size: rem-calc(14);
		line-height: 1.8rem;
		margin-top: 30px;
		margin-bottom: 10px;
		color: black;
		z-index: +1;

		@include breakpoint (medium only) {
			padding: 0 20px;
		}
	}

	h2 {
		@include breakpoint (medium down) {
			text-align: center;
		}
	}

	.screen {
		img {
			position: absolute;
			bottom: -20px;
			right: 50px;


			@include breakpoint (large only) {
				bottom: 60px;
				right: 50px;
				width: 40%;
				height: auto;
			}

			@include breakpoint (medium down) {
				position: relative;
				bottom: 0;
				left: 0;
				margin: auto;
			}
			@include breakpoint (small down) {
				padding: 20px;
				width: 85%;
			}
		}
	}
}

.info-icons {
	padding-top: 135px;
	padding-bottom: 250px;
	background: white;
	background-image: url(#{$theme-url-images}/backgrounds/background-3.png);
	background-repeat: no-repeat;
	background-position: center 10px;
	text-align: center;

	@include breakpoint (medium down) {
		background-image: none;
		padding-bottom: 100px;
	}

	@include breakpoint (small down) {
		margin-top: 30px;
	}

	h3 {
		text-transform: uppercase;
		font-size: rem-calc(20);
		letter-spacing: .06em;
		margin-top: 10px;
	}

	img {
		width: auto;
		height: auto;
	}
}

.action {
	text-align: center;
	margin-top: -50px;
	position: relative;

	.box-overflow {
		padding: 60px 90px;
		margin-top: -20px;
		margin-bottom: 95px;

		@include breakpoint (large down) {
			padding: 20px;
		}

		.icon {
			position: relative;
			margin-bottom: 40px;

			svg {
				position: absolute;
				top: 46%;
				left: 100%;
			}
		}

		.title {
			letter-spacing: .06em;
			line-height: 20px;
			position: relative;
			padding: 15px 0;
			font-family: $header-font-family;
			text-transform: uppercase;
			font-size: rem-calc(16);
			font-weight: normal;
		}

		.data {
			img {
				margin: -10px 0 10px -40px;
			}

			.title {
				margin-left: -40px;
			}

			svg {
				margin-left: -21px;
			}
		}

		.analyze {
			img {
				margin-left: -15px;

				@include breakpoint (small down) {
					margin-left: 40px;
				}
			}

			.title {
				margin-left: -20px;
				letter-spacing: .06em;
				font-weight: 400;

				@include breakpoint (small down) {
					margin-left: 20px;
				}
			}

			svg {
				margin-left: -5px;

				@include breakpoint (small down) {
					display: none;
				}
			}
		}

		.effect {
			img {
				margin-left: 10px;
				margin-top: 5px;

				@include breakpoint (small down) {
					margin-left: -40px;
				}
			}
			.title {
				margin-left: 15px;

				@include breakpoint (small down) {
					margin-left: -40px;
				}
			}
		}

		.alarm {
			img {
				margin-left: 55px;
				margin-top: 10px;
			}
			.title {
				margin-left: 45px;
				margin-top: -10px;
			}
		}

		h2 {
			margin-bottom: 50px;
			margin-top: 0;
		}

		.next-arrow {
			position: relative;
		}

		.video-wrapper {
			position: relative;
			overflow: hidden;
			height: 415px;
			transition: height .5s;
			border: 1px solid $blue;

			@include breakpoint (large down) {
				height: 360px;
			}

			@include breakpoint (medium down) {
				height: 260px;
				border: none;
			}

			@include breakpoint (small down) {
				height: 165px;
				border: none;
			}

			.play {
				display: flex;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				opacity: 1;
				z-index: +1;

				@include breakpoint (medium down) {
					width: 100%;
					min-height: 140px;
				}

				.play-button {
					position: absolute;
					border: 4px solid white;
					background: $blue;
					border-radius: 50%;
					height: 110px;
					width: 110px;
					padding: 25px;
					box-shadow: 0 0 20px 5px rgba(black, .2);
					transition: .2s;
					color: white;
					text-transform: uppercase;
					font-family: $header-font-family;
					font-size: rem-calc(15);
					font-weight: 900;
					letter-spacing: .06em;
					display: block;

					&:hover {
						cursor: pointer;
						opacity: .9;
					}

					.icon-16 {
						fill: none;
						stroke: white;
						display: block;
						text-align: center;
						margin: auto;
						width: 24px;
						height: 24px;
					}
				}
			}

			.cover {
				position: relative;
				width: 100%;
				height: 415px;
				overflow: hidden;
				z-index: +1;
			}

			.video {
				width: 100%;
				margin-bottom: -7px;
			}

			.animation {
				position: relative;
				z-index: 0;
				margin-top: -640px;
				height: 640px;
				width: 100%;


				@include breakpoint (medium down) {
					height: 500px;
				}

				@include breakpoint (small down) {
					height: auto;
				}
			}

			&.is-open {
				height: 640px;

				@include breakpoint (large down) {
					height: 550px;
				}

				@include breakpoint (small down) {
					height: 200px;
				}

				img {
					opacity: 0;
				}

				.cover {
					height: 640px;
					transition: height 1s;

					@include breakpoint (medium down) {
						opacity: 0;
						z-index: 0;
					}
				}

				.play {
					transition: .7s;
					opacity: 0;
					display: none;
				}

				.animation {
					z-index: +1;
				}
			}
		}
	}
}

.profits {
	margin-top: 160px;
	font-size: rem-calc(14);

	@include breakpoint (medium only) {
		margin-top: 0;
		padding: 0 80px 50px;
		background-image: none;
	}

	@include breakpoint (small down) {
		margin-top: 0;
	}


	h3 {
		color: $orange;
		font-size: rem-calc(25);
		line-height: 31px;
		padding-bottom: 10px;
	}

	.right {
		margin-left: 75px;

		@include breakpoint (medium down) {
			margin-left: 0;
		}
	}

	.profit-box {
		min-height: 265px;
		line-height: 24px;

		@include breakpoint (medium down) {
			min-height: auto;
		}
	}
}

.profit {
	margin-top: -110px;
	padding-top: 111px;
	padding-bottom: 70px;
	background-image: url(#{$theme-url-images}/backgrounds/background-4.png);
	background-repeat: no-repeat;
	background-position: center;

	@include breakpoint (medium down) {
		background-image: none;
		padding-top: 40px;
	}

	h2 {
		@include breakpoint (medium down) {
			text-align: center;
		}
	}
}

.case-studies {
	background: $medium-gray;
	padding-bottom: 55px;
	background-image: url(#{$theme-url-images}/backgrounds/background-6.png), url(#{$theme-url-images}/backgrounds/background-7.png);
	background-repeat: no-repeat;
	background-position: center top, center bottom;


	h2 {
		padding-top: 60px;
		text-align: center;
	}

	h4 {
		font-size: rem-calc(20);
		text-transform: uppercase;
	}

	.sc-menu {
		padding: 25px 0 0 25px;

		ul {
			list-style: none;
			margin-top: 10px;

			li {
				display: inline-block;
				color: $blue;
				text-transform: uppercase;
				text-align: center;
				font-family: $header-font-family;
				font-size: rem-calc(21);
				padding: 0 50px 22px;
			}
		}
	}

	.read-all {
		text-align: center;
		margin-top: -5px;
	}

	.case {
		color: $darker-gray;
		margin-top: 25px;

		@include breakpoint (medium down) {
			margin-top: 0;
		}

		.box-overflow {
			border-top: 4px solid black;
			margin-bottom: 0;

			@include breakpoint (large down) {
				padding: 30px;
			}

			@include breakpoint (medium only) {
			margin: 0;
			}

			@include breakpoint (small down) {
				padding: 15px;
			}

			p {
				margin: 0;
			}

			.description {
				img {
					padding: 30px 0;
				}
			}

			.partners {
				img {
					margin-top: -15px;
				}
			}

			.description,
			.reference {
				h4 {
					border-top: 1px solid $secondary-header;
					padding-top: 22px;
					padding-bottom: 20px;
				}
			}

			.case-title {
				color: $orange;
				font-family: $header-font-family;
				font-size: rem-calc(26);
				letter-spacing: .025em;
				margin-bottom: 35px;

				@include breakpoint (medium only) {
					text-align: center;
					padding-top: 20px;
				}
			}

			.case-info {
				font-family: $header-font-family;
				text-transform: uppercase;
				margin-bottom: 42px;
				padding-left: 30px;

				&:first-child {
					padding-left: 0;

					@include breakpoint (small down) {
						padding-left: 30px;
					}
				}

				@include breakpoint (medium only) {
					text-align: center;
				}

				h3 {
					color: $blue;
					margin-bottom: 0;
					font-size: rem-calc(40);
					font-weight: 100;
					letter-spacing: .1em;
				}

				p {
					letter-spacing: .06em;
					line-height: 20px;
					position: relative;
					padding: 15px 0;
					font-family: $header-font-family;
					text-transform: uppercase;
					font-size: rem-calc(16);
					font-weight: normal;
				}

				span {
					font-family: $body-font-family;
					font-size: rem-calc(13);
					text-transform: none;
				}
			}

			.reference {
				padding-left: 30px;

				@include breakpoint (medium down) {
					padding: 15px;
				}

				p {
					padding-left: 60px;
				}

				blockquote {
					font-style: italic;
					padding: 0 0 0 60px;
					margin: 0;
					position: relative;

					&::before {
						content: '';
						background-image: url(#{$theme-url-images}/icons/quote.svg);
						background-repeat: no-repeat;
						height: 40px;
						width: 40px;
						position: absolute;
						left: 0;
						top: 10px;
					}

					span {
						display: block;
						margin-top: 15px;
						font-weight: 600;
						font-style: normal;
						font-size: rem-calc(14);

						small {
							display: block;
							font-size: rem-calc(12);
							font-weight: 100;
						}
					}
				}

				.data {
					margin-top: 55px;

					ul {
						margin: 15px 0 0;
						padding: 0 0 0 10px;

						li {
							margin: 6px 0 0;
							list-style: none;
							font-size: rem-calc(14);
							padding-top: 0;

							&::before {
								content: '';
								background-color: $gray;
								border-radius: 50%;
								padding: 3px;
								display: inline-block;
								margin-right: 10px;
							}
						}
					}
				}
			}
		}
	}
}

.offer {
	background-image: url(#{$theme-url-images}/backgrounds/background-5.png);
	background-repeat: no-repeat;
	background-position: center top;
	padding-top: 30px;
	padding-bottom: 40px;
	font-size: rem-calc(14);

	@include breakpoint (medium down) {
		padding-bottom: 20px;
		background-image: none;
	}

	h2 {
		line-height: 1.17em;
		margin-top: 15px;
		margin-bottom: 75px;

		small {
			position: absolute;
			margin-right: -30px;
			margin-top: 15px;
			right: 0;

			@include breakpoint (small down) {
				right: 100%;
				top: -20px;
			}
		}

		@include breakpoint (medium down) {
			text-align: center;
		}
	}

	.left {
		text-align: right;
		margin-left: 25px;
		margin-top: 0;

		@include breakpoint (medium down) {
			text-align: left;
			margin-left: 0;
		}

		.box {
			padding: 30px 0;
			img {
				padding-bottom: 20px;
			}
			@include breakpoint (medium down) {
				text-align: center;
				padding: 20px 0;
			}
		}

		h3 {
			font-size: rem-calc(26);
			line-height: 35px;

			@include breakpoint(small down) {
				font-size: rem-calc(20);
			}
		}
	}

	.right {
		text-align: left;
		position: relative;
		margin-top: 0;

		@include breakpoint (medium down) {
			text-align: left;
		}

		.box {
			padding: 55px 0 0 50px;

			@include breakpoint (medium down) {
				text-align: center;
				padding: 20px 0;
			}
		}

		h3 {
			font-size: rem-calc(26);
			padding-top: 10px;

			@include breakpoint(small down) {
				font-size: rem-calc(20);
			}
		}

		ul {
			margin-left: 10px;
			margin-top: 20px;
		}
	}

	li {
		margin-bottom: 5px;
		list-style: none;
		font-size: 14px;
		padding-left: 0;
		letter-spacing: normal;
		line-height: normal;
		position: relative;

		&::before {
			content: '';
			background-color: $gray;
			border-radius: 50%;
			padding: 3px;
			display: inline-block;
			margin-right: 10px;
		}

		@include breakpoint (medium down) {
			text-align: left;
		}
	}
}

.about {
	.fp-intro {
		font-size: rem-calc(17);
		color: $gray;
		padding: 0 0 5px 30px;
		line-height: 1.5em;

		@include breakpoint (medium only) {
			padding: 0 20px;
		}

		@include breakpoint (large only) {
			padding-right: 20px;
		}
	}

	p {
		font-size: rem-calc(14);
		margin-top: 22px;
		line-height: 2em;
	}

	h2 {
		color: $white;
		padding-left: 25px;
		padding-bottom: 30px;

		@include breakpoint (large down) {
			padding-top: 20px;
		}
	}

	img {
		margin: 0 0 35px 35px;
	}

	@include breakpoint (small down) {
		padding: 5px;
	}

	.box-overflow-black {
		position: relative;
		background: $overflow;
		margin: 0 -90px 90px -90px;
		padding: 75px 90px 40px;
		z-index: +1;


		@include breakpoint (large down) {
			padding: 0;
			margin: 0;
		}
		@include breakpoint (small down) {
			width: 100%;
		}

		@include breakpoint (small down) {
			width: 100%;
		}

		.fp-photo {
			position: absolute;
			left: 0;
			top: 0;
			margin-left: -140px;

			@include breakpoint (large only) {
				margin-left: -200px;
				margin-top: 0;
				top: 20px 0;
			}

			@include breakpoint (medium down) {
				position: relative;
				margin: 0;
				width: 100%;
			}
		}
	}
}

.contact {
	position: relative;
	margin-top: -285px;
	padding-top: 200px;

	@include breakpoint (medium only) {
		padding: 0 20px;
		margin: 0;
	}

	@include breakpoint (large only) {
		padding: 0 60px;
		margin: 0;
	}

	.row {
		.full {
			max-width: 100%;
			height: auto;
		}
	}

	h2 {
		margin-top: 85px;

		@include breakpoint (large down) {
			margin-left: 20px;
		}

		@include breakpoint (medium down) {
			text-align: center;
		}
	}

	h3 {
		font-size: rem-calc(26);
		margin-top: 55px;
		margin-bottom: 20px;
	}

	.maths {
		padding: 20px 0 0 40px;
		position: relative;
		color: $white;

		@include breakpoint (small down) {
			padding: 20px 0 0 20px;
			margin-top: 20px;
		}

		p {
			margin-top: 5px;
			margin-bottom: 20px;
		}

		h3 {
			color: $white;
			margin-top: 100px;
			letter-spacing: .055em;

			@include breakpoint (medium down) {
				margin-top: 0;
			}
		}

		.button {
			margin-top: 0;
		}

		&::after {
			content: '';
			background-color: $blue;
			bottom: 0;
			top: -255px;
			left: 0;
			right: -230px;
			position: absolute;
			display: block;
			z-index: -1;

			@include breakpoint (large down) {
				top: 0;
				right: -60px;
				overflow: hidden;
			}

			@include breakpoint (medium down) {
				top: 0;
				right: 0;
			}
		}
	}
}

.get-case-study{
	margin: 0 0 40px;
    padding-bottom:20px;
    border-bottom: 1px solid black;
}

.logos-case-study {
    display: block;
    /* width: 100px; */
    height: 50px;
    margin: 0 auto 5px;
}

.center {
    text-align: center;
}

.img-qrcode {
	display: inline-block;
	width: 180px;
}
